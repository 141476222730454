import './App.css';
import Main from './pages/main';
import { BrowserRouter , Route, Link, Routes } from 'react-router-dom';
import SubmissionForm from './pages/register';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main/>}/>
          <Route path='/register' element = {<SubmissionForm/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
