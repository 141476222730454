import React from 'react'

function Footer() {
  return (
    <div className='flex justify-center items-center py-5'>
        <div>© Copyright Eldo George. All Rights Reserved</div>
    </div>
  )
}

export default Footer