import React from 'react';

function Schedule() {
    const schedules = {
        1: ['Understanding your beliefs', 'Revising the structure of future', 'Mind power day 1'],
        2: ['Developing the right Attitude', 'Affirmations creation', 'Goal designing', 'Mind power day 2'],
        3: ['Making the higher self image', 'Creating the higher priority actions', 'Mind power day 3'],
        4: ['Breaking the resistance ', 'Character formulation', 'Mind power day 4'],
        5: ['Morning rituals', 'Night rituals', 'Mind power day 5'],
    };

    return (
        <div className='flex flex-col justify-center items-center pt-20'>
            <div className='text-5xl font-bold capitalize bg-green-600 px-4 py-4 rounded-xl -skew-x-6 text-white'>What will you learn in this webinar?</div>
            <div className='flex flex-col flex-wrap gap-5 justify-center items-center pt-10 w-[700px]'>
                <div className='flex flex-wrap lg:flex-nowrap justify-center items-center gap-5 w-[700px]'>
                    <div className='bg-green-50 w-full py-10 px-10 text-2xl font-semibold rounded-xl flex justify-center items-center border-l-4 border-t-4 border-green-300 shadow-lg'>5 days</div>
                    <div className='bg-green-50 w-full py-10 px-10 text-2xl font-semibold rounded-xl flex justify-center items-center border-r-4 border-t-4 border-green-300 shadow-lg'> 3.75 hours</div>
                </div>
                <div className='bg-green-50 w-full py-10 px-10 text-2xl font-semibold rounded-xl flex flex-col justify-center items-center border-l-4 border-r-4 border-b-4 border-green-300 shadow-lg'>5 simple challenges<br/>
                    <span>to achieve the massive massive results by exploring the power of mind</span>
                </div>
            </div>
            <div className='py-20 flex flex-wrap justify-center gap-10'>
                {Object.entries(schedules).map(([day, topics]) => (
                    <div className='flex flex-col text-left w-96 px-5 py-8 rounded-xl gap-5 bg-green-200 shadow-lg' key={day}>
                        <div className=' text-3xl font-bold text-green-900'>{`Day ${day}`}</div>
                        <div className='flex flex-wrap gap-2 h-full items-center bg-green-100  rounded-xl p-3'>
                            {topics.map(topic => (
                                <div className=' bg-green-600 text-white py-2 px-4 rounded-full' key={topic}>{topic}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Schedule;