import React from 'react';
import career from '../assets/icons/career growth.png'
import focus from '../assets/icons/focus.png'
import mindset from '../assets/icons/mindset.png'
import goal from '../assets/icons/goal.png'

function Hero() {
  return (
    <div className='flex flex-col mx-5 justify-center items-center'>
        <div className='relative w-full flex flex-col overflow-hidden py-56 justify-center items-center bg-black rounded-xl '>
            <img className='absolute z-0 opacity-50' src='https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt='img'/>
            <div className=' absolute z-10 h-full w-full bg-gradient-to-bl from-green-300 to-green-900 opacity-20'></div>
            <div className=' relative px-40 flex flex-col gap-5 z-10 text-white'>
                <div className='text-2xl'>This workshop is for students who are transforming to the professional line where they get a clarity on the future readiness aspects</div>
                <div className='text-7xl font-bold capitalize bg-green-600 px-4 py-2 rounded-xl -skew-x-6'>3x career goals using eldo’s mind performance formula</div>
                <div className='text-2xl'>Effortlessly double your career goals in accordance with lifestyle and academics 5 days program</div>
            </div>
        </div>
        <div>
            <div className='pt-36'>
                <div className='relative flex justify-center items-end'>
                    {/* <div className=' absolute translate-x-20 translate-y-5 w-80 h-16 bg-gradient-to-r from-green-300 to-green-500 -skew-x-12'></div> */}
                    <div className=' relative z-10 -translate-y-16 text-5xl font-extrabold capitalize text-green-900'>How does your</div>
                    <div className=' absolute z-0 text-5xl font-extrabold capitalize bg-green-600 px-4 py-2 rounded-xl -skew-x-6 text-white'>career goals help you</div>
                </div>
                <div className='flex flex-wrap justify-center items-center gap-10 pt-20'>
                    <div className=' flex flex-col w-80 h-56 shadow-lg bg-green-100 items-center justify-center rounded-xl gap-3'>
                        <img src={career} className='w-20 h-auto' alt='career'/>
                        <div className=' text-2xl font-semibold capitalize'>Quick career growth</div>
                    </div>
                    <div className=' flex flex-col w-80 h-56 shadow-lg bg-green-100 items-center justify-center rounded-xl gap-3'>
                        <img src={focus} className='w-20 h-auto' alt='focus'/>
                        <div className=' text-2xl font-semibold capitalize'>Improved focus</div>
                    </div>
                    <div className=' flex flex-col w-80 h-56 shadow-lg bg-green-100 items-center justify-center rounded-xl gap-3'>
                        <img src={mindset} className='w-20 h-auto' alt='mindset'/>
                        <div className=' text-2xl font-semibold capitalize'>Great mindset</div>
                    </div>
                    <div className=' flex flex-col w-80 h-56 shadow-lg bg-green-100 items-center justify-center rounded-xl gap-3'>
                        <img src={goal} className='w-20 h-auto' alt='goal'/>
                        <div className='text-2xl font-semibold capitalize'>Structured approach in reaching the destination</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero