import React, { useEffect, useState } from 'react'

function Timer() {
  const [timeRemaining, setTimeRemaining] = useState(null);
    
    const eventDate = new Date('2024-03-15T18:30:00');
    
    useEffect(() => {
        const calculateTimeRemaining = () => {
        const now = new Date();
        const then = new Date(eventDate);
        const difference = then - now;

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeRemaining({ days, hours, minutes, seconds });
        } else {
            setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
    };

    calculateTimeRemaining();
    const intervalId = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(intervalId); // Cleanup function
  },[eventDate]);

  const renderTimeRemaining = () => {
    if (!timeRemaining) {
      return <p>Calculating...</p>;
    }

    const { days, hours, minutes, seconds } = timeRemaining;
    const formattedTime = `${days ? days + 'days' : ''}${hours ? hours + 'hours' : ''}${minutes ? minutes + 'mins' : ''}${seconds}sec`;
    
    return (
      <div className='flex flex-wrap justify-center items-center drop-shadow-lg'>
        <div className='w-80 h-40 flex flex-col justify-center items-center bg-green-100 border-r-2 border-green-300 gap-2 rounded-l-xl'>
          <div className='text-7xl font-bold text-green-900'>{days}</div>
          <div className=' text-xl font-semibold'>DAYS</div>
        </div>
        <div className='w-80 h-40 flex flex-col justify-center items-center bg-green-100 border-r-2 border-green-300 gap-2'>
          <div className='text-7xl font-bold text-green-900'>{hours}</div>
          <div className=' text-xl font-semibold'>HOURS</div>
        </div>
        <div className='w-80 h-40 flex flex-col justify-center items-center bg-green-100 border-r-2 border-green-300 gap-2'>
          <div className='text-7xl font-bold text-green-900'>{minutes}</div>
          <div className=' text-xl font-semibold'>MINUTES</div>
        </div>
        <div className='w-80 h-40 flex flex-col justify-center items-center bg-green-100 border-r-0 border-green-300 gap-2 rounded-r-xl'>
          <div className='text-7xl font-bold text-green-900'>{seconds}</div>
          <div className=' text-xl font-semibold'>SECONDS</div>
        </div>
      </div>
    );
  };

  return (
    <div className=' py-36'>
      <div className=' flex flex-col gap-5 mx-80'>
        <div className=' text-4xl font-bold capitalize text-green-950'>the live event will be on</div>
        <div className=' text-5xl font-bold capitalize bg-green-600 px-4 py-4 rounded-xl -skew-x-6 text-white'>12th march 2024, 7:20 PM</div>
      </div>
      <div className='pt-10'>
        {renderTimeRemaining()}
      </div>
    </div>
  )
}

export default Timer