import axios from 'axios';
import React, { useState } from 'react';

function SubmissionForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [token, settoken] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    
    axios.post('http://localhost:3001/api/get-token')
    .then(res => {
      settoken(res.data);
    })

    const Udata = {
      name,
      email,
      phoneNumber,
      token
    }
    console.log(Udata)

    axios.post('http://localhost:3001/api/create-order', Udata) //********add url********
    .then((response) => {
      console.log(response)
      alert("Submitted Successfully!");
      setName('')
      setEmail('')
      setPhoneNumber('')
      settoken('')
    })
    .catch((error) => {
      console.log(error)
      alert("Error with submission!")
    })
  };

  return (
    <div className='flex flex-col justify-center items-center h-[100vh]'>
      <div className='pb-8'>
        <h1 className=' text-5xl font-bold'>Register Now</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col justify-start items-start gap-2 px-5 pt-5'>
          <label htmlFor="name">Name :</label>
          <input
            className=' w-64 rounded-lg'
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            />
        </div>
        <div className='flex flex-col justify-start items-start gap-2 px-5 py-5'>
          <label htmlFor="email">Email :</label>
          <input
            className=' w-64 rounded-lg'
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            />
        </div>
        <div className='flex flex-col justify-start items-start gap-2 px-5'>
          <label htmlFor="phoneNumber">Phone Number :</label>
          <input
            className=' w-64 rounded-lg'
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            required
            />
        </div>
        <div className=' flex items-start px-5 py-5'>
          <button className='px-5 py-2 bg-blue-400 rounded-xl text-black' type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default SubmissionForm;