import React from 'react'
import { Link } from 'react-router-dom';
import SubmissionForm from '../pages/register';

function Register1() {


  return (
    <div className=' flex justify-center items-center mb-10'>
      <div className=' w-[700px] flex justify-between items-center px-24 py-8 bg-green-600 rounded-xl -skew-x-6'>
        <div className='flex flex-col justify-center items-start'>
            <div className=' flex flex-col items-start'>
                <div className='flex text-2xl font-semibold text-green-100'>Register for</div>
                <div className='flex text-7xl font-bold text-white'>1999/-</div>
            </div>
        </div>
        <Link to='/register' target='blank'>
        <button className=' text-4xl bg-green-900 px-8 py-4 -skew-x-6 text-left text-white rounded-xl'>Register<br/>Now</button>
        </Link>
      </div>
    </div>
  )
}

export default Register1