import React from 'react'

function Smalldete() {
  return (
    <div className=' flex flex-wrap justify-center items-center gap-20'>
        <div className='flex flex-col bg-green-200 shadow-lg py-5 px-20 text-black rounded-xl text-5xl font-bold -skew-x-6 gap-3'>2000+ <span className='text-xl font-semibold'>professionals trained</span></div>
        <div className='flex flex-col bg-green-200 shadow-lg py-5 px-20 text-black rounded-xl text-5xl font-bold -skew-x-6 gap-3'>600+ <span className='text-xl font-semibold'>live sessions taken</span></div>
    </div>
  )
}

export default Smalldete