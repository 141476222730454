import {React,  useState } from 'react'
import { FiArrowDown } from 'react-icons/fi';

function Faq() {
    const [activeQuestion, setActiveQuestion] = useState(null);

  const questions = [
    {
      title: 'How will i get the link to attend the programme?',
      answer: '06-03-2024 Wednesday, 06:30PM IST',
    },
    {
      title: 'How will i get the bonus items?',
      answer: 'You will receive the bonus on the last day.',
    },
    {
      title: 'Will i get the recordings of the programme?',
      answer: "As its live session we dont get the recordings.",
    },
    {
        title: 'Who is the seminar ideal for?',
        answer: "Only for students , professionals , teachers.",
    },
    {
        title: 'In which language the workshop is conducted?',
        answer: "The workshop is conducted in english language only.",
    },
    {
        title: 'What are the timings of the workshop ?',
        answer: '5 day live session from monday to friday @ 05:00 am to 5:45 am.',
    },
    {
        title: 'Is this workshop an advanced mind  accelerator worskop ?',
        answer:'This class is for people who wanted to get  started on the streamline theory fast ability to devolop there mind and brain.', 
    }
  ];

  const handleClick = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
  };

  return (
    <div className="faq-section flex flex-col justify-center items-center gap-2 pt-10 pb-20 sm:mx-10">
      <h2 className='text-4xl font-bold mb-12 text-center bg-green-600 px-4 py-4 rounded-xl -skew-x-6 text-white'>Frequently Asked Questions</h2>
      {questions.map((question, index) => (
        <div className='lg:w-[700px] md:w-[600px] w-[310px] bg-green-50 p-5 rounded-lg'>
        <div key={index} className={`faq-item text-left flex flex-col ${activeQuestion === index ? 'active' : ''}`}>
            {/* <div className='flex'> */}
                <button className='flex justify-between text-left' onClick={() => handleClick(index)}>
                    <p className='flex justify-start'>{question.title}</p>
                    <div className=' flex flex-row justify-end items-center content-end pl-5'><FiArrowDown/></div>
                </button>
            {activeQuestion === index && <p className='pt-5'>{question.answer}</p>}
        </div>
        </div>
      ))}
    </div>
  )
}

export default Faq