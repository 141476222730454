import React from 'react'
import { Link } from 'react-router-dom'

function Bonous () {
  return (
    <div className=' py-20'>
        <div className=' flex flex-col justify-center items-center'>
            <div className=' text-5xl font-bold capitalize bg-green-600 px-4 py-4 rounded-xl -skew-x-6 text-white'>Register now and get 3 bonuses </div>
            <div className=' flex justify-center items-center gap-10 py-16'>
                <div className='w-80 h-36 bg-green-200 shadow-lg px-10 py-20 gap-2 flex flex-col justify-center items-center rounded-xl'>
                    <div className='text-2xl font-semibold bg-green-600 px-2 py-2 rounded-xl -skew-x-6 text-white'>Bonus 1</div>
                    <div className='text-xl text-green-900'>Top one percent model</div>
                </div>
                <div className='w-80 h-36 bg-green-200 shadow-lg px-10 py-20 gap-2 flex flex-col justify-center items-center rounded-xl'>
                    <div className='text-2xl font-semibold bg-green-600 px-2 py-2 rounded-xl -skew-x-6 text-white'>Bonus 2 </div>
                    <div className='text-xl text-green-900'>Exclusive certificate of appreciation</div>
                </div>
                <div className='w-80 h-36 bg-green-200 shadow-lg px-10 py-20 gap-2 flex flex-col justify-center items-center rounded-xl'>
                    <div className='text-2xl font-semibold bg-green-600 px-2 py-2 rounded-xl -skew-x-6 text-white'>Bonus 3 </div>
                    <div className='text-xl text-green-900'>Facebook vip private group</div>
                </div>
            </div>
        </div>
        <div className='w-full bg-green-600 flex flex-col justify-center items-center py-12 gap-4'>
            <div className=' text-xl font-semibold text-green-100'>Join & learn mind enhancement strategy</div>
            <div className=' flex flex-col gap-8 justify-center items-center'>
                <div className=' text-5xl font-bold capitalize text-white'>Register now for 1999/- only</div>
                <Link to='/register' target='blank'>
                <button className='text-3xl font-bold bg-white px-4 py-4 rounded-xl -skew-x-6 text-green-600'>Register Now</button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Bonous 