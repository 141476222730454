import React from 'react'
import Hero from '../components/hero'
import Timer from '../components/timer'
import Navbar from '../components/navbar'
import Register1 from '../components/register1'
import Smalldete from '../components/smalldete'
import Question from '../components/question'
import Learn from '../components/learn'
import Faq from '../components/faq'
import Bonous from '../components/bonous '
import About from '../components/about'
import Gallery from '../components/gallery'
import Schedule from '../components/schedule'
import Footer from '../components/footer'
import Featuredin from '../components/fearured'

function Main() {
  return (
    <div>
        <Navbar/>
        <Hero/>
        <Timer/>
        <Register1/>
        <Smalldete/>
        <Featuredin/>
        <Question/>
        <Register1/>
        {/* <Learn/> */}
        <Schedule/>
        <Register1/>
        <About/>
        <Gallery/>
        <Bonous/>
        <Faq/>
        <Register1/>
        <Footer/>
    </div>
  )
}

export default Main