import React from 'react';
import point from '../assets/icons/point.png'

function Question() {
  return (
    <div className=' flex flex-row flex-wrap gap-10 justify-center mx-20 my-20'>
        <div className='flex flex-col justify-center items-start py-10 px-12 bg-slate-100 border-b-4 border-green-300 rounded-xl gap-4'>
            <div className='text-2xl font-semibold text-green-950'>Are you facing any of these challenges?</div>
            <div className=' flex flex-col gap-2 items-start'>
                <div className='flex gap-2'>
                    <img className='w-5' src={point} alt=' '/>
                    <div>Not sure about what to take a future career</div>
                </div>
                <div className='flex gap-2'>
                    <img className='w-5' src={point} alt=' '/>
                    <div>Dont know how the academics could be linked with future career</div>
                </div>
                <div className='flex gap-2'>
                    <img className='w-5' src={point} alt=' '/>
                    <div>No claritry about the self image</div>
                </div>
                <div className='flex gap-2'>
                    <img className='w-5' src={point} alt=' '/>
                    <div>Overwhelming with a lot of information</div>
                </div>
            </div>
        </div>
        <div className='flex flex-col justify-center items-start py-10 px-12 bg-slate-100 border-b-4 border-green-300 rounded-xl gap-4'>
            <div className='text-2xl font-semibold text-green-950'>Difficulty in staying focussed?</div>
            <div className=' flex flex-col gap-2 items-start'>
                <div className='flex gap-2'> 
                    <img className='w-5' src={point} alt=' '/>
                    <div>Time management struggles</div>
                </div>
                <div className='flex gap-2'> 
                    <img className='w-5' src={point} alt=' '/>
                    <div>Memory retention trouble</div>
                </div>
                <div className='flex gap-2'> 
                    <img className='w-5' src={point} alt=' '/>
                    <div>Dont know to how to move to a independency mode</div>
                </div>
            </div>
        </div>    
    </div>
  )
}

export default Question